/*
////////////////////////////////////////////////////////////////

Author: Free-Template.co
Author URL: http://free-template.co.
License: https://creativecommons.org/licenses/by/3.0/
License URL: https://creativecommons.org/licenses/by/3.0/
Site License URL: https://free-template.co/template-license/
  
Website:  https://free-template.co
Facebook: https://www.facebook.com/FreeDashTemplate.co
Twitter:  https://twitter.com/Free_Templateco
RSS Feed: https://feeds.feedburner.com/Free-templateco

////////////////////////////////////////////////////////////////
*/
html {
  overflow-x: hidden;
}

body {
  background: #fff;
  font-family: "Open Sans", Arial, sans-serif;
  font-weight: 400;
  font-size: 15px;
  line-height: 1.8;
  color: #868e96;
}

a {
  -webkit-transition: 0.3s all ease;
  -o-transition: 0.3s all ease;
  transition: 0.3s all ease;
  text-decoration: none;
}

a:hover {
  text-decoration: none;
}

h1,
h2,
h3,
h4,
h5 {
  color: #000;
}

.btn,
.form-control {
  outline: none;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  border-radius: 0px !important;
}

.btn:focus,
.btn:active,
.form-control:focus,
.form-control:active {
  outline: none;
}

.form-control {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  height: 46px;
  border-width: 1px;
}

.form-control:active,
.form-control:focus {
  border-color: #ff7404;
}

textarea.form-control {
  height: inherit;
}

.btn {
  font-size: 13px;
  text-transform: capitalize;
  letter-spacing: 0.2em;
  font-weight: 500;
  background-color: #4361ee;
  color: #fff;
  border: none;
  border-radius: 20px;
  -webkit-transition: 0.3s all ease;
  -o-transition: 0.3s all ease;
  transition: 0.3s all ease;
}

.btn.btn-primary {
  color: #fff;
}

.btn.btn-primary:hover,
.btn.btn-primary:active,
.btn.btn-primary:focus {
  background: #ff9037;
}

.btn.btn-sm {
  font-size: 12px;
}

.btn.btn-outline-primary {
  border-width: 2px;
  color: #ff7404;
}

.btn.btn-outline-primary:hover,
.btn.btn-outline-primary:focus,
.btn.btn-outline-primary:active {
  color: #fff;
}

.btn.btn-outline-white {
  border-width: 2px;
  border-color: #fff;
  color: #fff;
}

.btn.btn-outline-white:hover,
.btn.btn-outline-white:focus {
  background: #fff;
  color: #000 !important;
  border-width: 2px;
}

.btn:hover {
  text-decoration: none;
}

/* Header */
header {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 5;
  padding: 0px 0;
}

@media (min-width: 768px) {
  header {
    padding: 30px 0;
  }
}

header .navbar-brand {
  text-transform: uppercase;
  letter-spacing: 0.2em;
  font-weight: 800;
  font-size: 2rem;
}

header .navbar-brand.absolute {
  position: absolute;
}

.navbar-collapse {
  justify-content: flex-end;
}

@media (max-width: 991px) {
  header .navbar-brand.absolute {
    position: relative;
  }
}

header .navbar-brand span {
  color: #ff7404;
}

@media (min-width: 768px) {
  header .navbar-brand span {
    color: #ced4da;
  }
}

header .navbar {
  background: black !important;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

@media (min-width: 768px) {
  header .navbar {
    padding-top: 0;
    padding-bottom: 0;
    background: none !important;
    position: relative;
  }
}

header .navbar .nav-link {
  padding: 1.7rem 1rem;
  outline: none !important;
  font-size: 1rem;
  color: white !important;
}

@media (max-width: 1199px) {
  header .navbar .nav-link {
    padding: 0.5rem 0rem;
  }
}

header .navbar .nav-link.active {
  color: #ff7404 !important;
}

header .navbar .dropdown-menu {
  font-size: 14px;
  border-radius: 4px;
  border: none;
  -webkit-box-shadow: 0 2px 30px 0px rgba(0, 0, 0, 0.2);
  box-shadow: 0 2px 30px 0px rgba(0, 0, 0, 0.2);
  min-width: 13em;
  margin-top: -10px;
}

header .navbar .dropdown-menu:before {
  bottom: 100%;
  left: 10%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-bottom-color: #fff;
  border-width: 7px;
}

@media (max-width: 991px) {
  header .navbar .dropdown-menu:before {
    display: none;
  }
}

header .navbar .dropdown-menu .dropdown-item:hover {
  background: #ff7404;
  color: #fff;
}

header .navbar .dropdown-menu .dropdown-item.active {
  background: #ff7404;
  color: #fff;
}

header .navbar .dropdown-menu a {
  padding-top: 7px;
  padding-bottom: 7px;
}

header .navbar .cta-btn a {
  background: #ff7404;
  border-radius: 20px;
  margin-left: 10%;
  color: #fff !important;
  text-transform: uppercase;
  font-size: 0.8rem;
  padding: 15px 20px !important;
  line-height: 1;
  font-weight: bold;
  -webkit-transition: 0.3s all ease;
  -o-transition: 0.3s all ease;
  transition: 0.3s all ease;
}

header .navbar .cta-btn a:hover {
  background: #fff;
  color: #ff7404 !important;
  -webkit-box-shadow: 2px 0 30px -5px rgba(0, 0, 0, 0.2);
  box-shadow: 2px 0 30px -5px rgba(0, 0, 0, 0.2);
}

.half .text,
.half .image {
  width: 50%;
}

@media (max-width: 991px) {
  .half .text,
  .half .image {
    width: 100%;
  }
}

.half .text {
  padding: 7%;
}

.half .image {
  background-size: cover;
  background-position: center center;
}

@media (max-width: 991px) {
  .half .image {
    height: 350px;
  }
}

.section-heading h2 {
  font-size: 50px;
}

.section-heading .small-sub-heading {
  font-size: 14px;
  color: #ff7404;
}

.blog .media h3 {
  font-size: 20px;
  margin-bottom: 10px;
}

.blog .media .post-meta {
  font-size: 14px;
  letter-spacing: 0.2em;
  text-transform: uppercase;
}

.blog .media > a {
  width: 40%;
}

@media (max-width: 767px) {
  .blog .media > a {
    width: 100%;
    margin-bottom: 30px;
  }
}

.item-dishes {
  position: relative;
  display: block;
  overflow: hidden;
}

.item-dishes img {
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
  -webkit-transition: 0.3s all ease-out;
  -o-transition: 0.3s all ease-out;
  transition: 0.3s all ease-out;
  z-index: 1;
}

.item-dishes:before {
  position: absolute;
  content: "";
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  z-index: 2;
  background: #000;
  opacity: 0.3;
}

.item-dishes .text {
  z-index: 3;
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  width: 100%;
  text-align: center;
}

.item-dishes .text .dishes-heading {
  font-size: 30px;
  font-weight: 700;
  max-width: 500px;
  margin: 0 auto;
  text-transform: uppercase;
  color: #fff;
}

@media (max-width: 991px) {
  .item-dishes .text .dishes-heading {
    font-size: 1.3rem;
  }
}

.item-dishes .text .dishes-price {
  font-weight: 700;
  font-size: 20px;
  color: #fff;
  display: inline-block;
  background: #ff7404;
  width: 100px;
  height: 100px;
  line-height: 100px;
  border-radius: 50%;
  -webkit-transition: 0.3s all ease;
  -o-transition: 0.3s all ease;
  transition: 0.3s all ease;
  border: 2px solid transparent;
}

.item-dishes:hover img {
  -webkit-transform: scale(1.1);
  -ms-transform: scale(1.1);
  transform: scale(1.1);
}

.item-dishes:hover .dishes-price {
  background: transparent;
  border: 2px solid #fff;
}

.heading-wrap {
  position: relative;
}

.heading-wrap h2 {
  font-size: 50px;
  position: relative;
  padding-top: 30px;
  z-index: 2;
  text-transform: uppercase;
  font-weight: 800;
}

.heading-wrap h2:before {
  width: 80px;
  height: 2px;
  background: #ff7404;
  content: "";
  position: absolute;
  top: 0;
  left: 50%;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
}

.heading-wrap .back-text,
.heading-wrap .back-text-dark {
  z-index: 0;
  line-height: 1;
  top: 0.5em;
  left: 50%;
  margin-top: -50px;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
  font-weight: 800;
  font-size: 200px;
  position: absolute;
  width: 100%;
  color: #f7f7f7;
  text-transform: uppercase;
}

.heading-wrap .back-text-dark {
  color: #f2f2f2 !important;
}

.lead {
  font-size: 16px;
}

.testimonial p {
  font-size: 20px;
  color: #000;
}

.testimonial .author img {
  width: 80px;
  height: 80px;
  border-radius: 50%;
}

.testimonial .author .author-info {
  margin-top: 20px;
}

.testimonial .author h4 {
  font-size: 18px;
  margin-bottom: 0;
}

.testimonial .author p {
  color: #cccccc;
  font-size: 14px;
}

.sched,
.blog {
  display: block;
  background-color: #fff;
}

.sched .text,
.sched .bg-image,
.blog .text,
.blog .bg-image {
  width: 100%;
}

@media (min-width: 992px) {
  .sched .text,
  .sched .bg-image,
  .blog .text,
  .blog .bg-image {
    width: 50%;
  }
}

.sched h3,
.blog h3 {
  font-size: 20px;
}

.sched .text,
.blog .text {
  padding: 30px;
}

.sched .sched-time,
.blog .sched-time {
  font-size: 13px;
  color: #999999;
}

.sched .bg-image,
.blog .bg-image {
  background-size: cover;
  background-position: center center;
  border-radius: 10px;
  height: 200px;
  width: 300px;
}

@media (max-width: 991px) {
  .sched .bg-image,
  .blog .bg-image {
    height: 200px;
  }
}

@media (min-width: 768px) {
  .blog {
    margin-bottom: 30px;
  }
}

.blog .text,
.blog .bg-image {
  width: 100%;
}

@media (min-width: 992px) {
  .blog .text,
  .blog .bg-image {
    width: 100%;
  }
}

.blog .bg-image {
  height: 300px;
}

.custom-icon {
  font-size: 70px;
  color: #ff7404;
}

/* Owl Carousel */
.owl-carousel .owl-item {
  -webkit-transition: 0.3s all ease-in-out;
  -o-transition: 0.3s all ease-in-out;
  transition: 0.3s all ease-in-out;
  opacity: 0.2;
}

.owl-carousel .owl-item.active {
  opacity: 1;
}

.owl-carousel .owl-nav {
  position: absolute;
  top: 50%;
  width: 100%;
}

.owl-carousel .owl-nav .owl-prev,
.owl-carousel .owl-nav .owl-next {
  position: absolute;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  margin-top: -10px;
  opacity: 1;
  -webkit-transition: 0.3s all ease;
  -o-transition: 0.3s all ease;
  transition: 0.3s all ease;
}

.owl-carousel .owl-nav .owl-prev:hover,
.owl-carousel .owl-nav .owl-prev:focus,
.owl-carousel .owl-nav .owl-prev:active,
.owl-carousel .owl-nav .owl-next:hover,
.owl-carousel .owl-nav .owl-next:focus,
.owl-carousel .owl-nav .owl-next:active {
  outline: none;
  opacity: 1;
}

.owl-carousel .owl-nav .owl-prev span:before,
.owl-carousel .owl-nav .owl-next span:before {
  font-size: 40px;
}

.owl-carousel .owl-nav .owl-prev {
  left: 30px !important;
}

.owl-carousel .owl-nav .owl-next {
  right: 30px !important;
}

.owl-carousel .owl-dots {
  text-align: center;
}

.owl-carousel .owl-dots .owl-dot {
  width: 10px;
  height: 10px;
  margin: 5px;
  border-radius: 50%;
  background: none;
  background: #fff;
  opacity: 0.5;
}

.owl-carousel .owl-dots .owl-dot.active {
  opacity: 1;
}

.owl-carousel.centernonloop .owl-dots,
.owl-carousel.centernonloop2 .owl-dots {
  margin-top: 50px;
}

.owl-carousel.centernonloop .owl-dots.disabled,
.owl-carousel.centernonloop2 .owl-dots.disabled {
  display: block !important;
}

.owl-carousel.centernonloop .owl-dots .owl-dot,
.owl-carousel.centernonloop2 .owl-dots .owl-dot {
  display: inline-block;
}

.owl-carousel.centernonloop .owl-dots .owl-dot > span,
.owl-carousel.centernonloop2 .owl-dots .owl-dot > span {
  display: inline-block;
  width: 7px;
  height: 7px;
  background: #ccc;
  -webkit-transition: 0.3s all ease-in-out;
  -o-transition: 0.3s all ease-in-out;
  transition: 0.3s all ease-in-out;
}

.owl-carousel.centernonloop .owl-dots .owl-dot.active > span,
.owl-carousel.centernonloop2 .owl-dots .owl-dot.active > span {
  background: #ff7404;
  -webkit-box-shadow: 0 1px 5px 0px rgba(255, 116, 4, 0.3);
  box-shadow: 0 1px 5px 0px rgba(255, 116, 4, 0.3);
}

.owl-carousel.centernonloop .owl-dots .owl-dot:active,
.owl-carousel.centernonloop .owl-dots .owl-dot:focus,
.owl-carousel.centernonloop2 .owl-dots .owl-dot:active,
.owl-carousel.centernonloop2 .owl-dots .owl-dot:focus {
  outline: none;
}

.owl-carousel.home-slider,
.owl-carousel.home-slider-loop-false {
  z-index: 1;
  position: relative;
}

.owl-carousel.home-slider .btn-link,
.owl-carousel.home-slider-loop-false .btn-link {
  color: #fff;
}

.owl-carousel.home-slider .owl-nav,
.owl-carousel.home-slider-loop-false .owl-nav {
  opacity: 0;
  visibility: hidden;
  -webkit-transition: 0.3s all ease;
  -o-transition: 0.3s all ease;
  transition: 0.3s all ease;
}

.owl-carousel.home-slider .owl-nav button,
.owl-carousel.home-slider-loop-false .owl-nav button {
  color: #fff;
}

.owl-carousel.home-slider:focus .owl-nav,
.owl-carousel.home-slider:hover .owl-nav,
.owl-carousel.home-slider-loop-false:focus .owl-nav,
.owl-carousel.home-slider-loop-false:hover .owl-nav {
  opacity: 1;
  visibility: visible;
}

.owl-carousel.home-slider .slider-item,
.owl-carousel.home-slider-loop-false .slider-item {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  height: calc(100vh);
  min-height: 700px;
  position: relative;
}

.owl-carousel.home-slider .slider-item:before,
.owl-carousel.home-slider-loop-false .slider-item:before {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.2);
  content: "";
}

.owl-carousel.home-slider .slider-item .slider-text,
.owl-carousel.home-slider-loop-false .slider-item .slider-text {
  color: #fff;
  height: calc(100vh);
  min-height: 700px;
}

.owl-carousel.home-slider .slider-item .slider-text .child-name,
.owl-carousel.home-slider-loop-false .slider-item .slider-text .child-name {
  font-size: 40px;
  color: #fff;
}

.owl-carousel.home-slider .slider-item .slider-text h1,
.owl-carousel.home-slider-loop-false .slider-item .slider-text h1 {
  font-size: 5rem;
  color: #fff;
  line-height: 1.2;
  font-weight: 800 !important;
  text-transform: uppercase;
}

@media (max-width: 991px) {
  .owl-carousel.home-slider .slider-item .slider-text h1,
  .owl-carousel.home-slider-loop-false .slider-item .slider-text h1 {
    font-size: 40px;
  }
}

.owl-carousel.home-slider .slider-item .slider-text p,
.owl-carousel.home-slider-loop-false .slider-item .slider-text p {
  font-size: 20px;
  line-height: 1.5;
  font-weight: 300;
  color: white;
}

.owl-carousel.home-slider .slider-item .slider-text a,
.owl-carousel.home-slider-loop-false .slider-item .slider-text a {
  color: #fff;
  text-decoration: underline;
}

.owl-carousel.home-slider .slider-item .slider-text .btn,
.owl-carousel.home-slider-loop-false .slider-item .slider-text .btn {
  text-decoration: none;
}

.owl-carousel.home-slider.inner-page .slider-item,
.owl-carousel.home-slider-loop-false.inner-page .slider-item {
  height: calc(50vh - 117px);
  min-height: 550px;
}

.owl-carousel.home-slider.inner-page .slider-item .slider-text,
.owl-carousel.home-slider-loop-false.inner-page .slider-item .slider-text {
  color: #fff;
  height: calc(50vh - 117px);
  min-height: 550px;
}

.owl-carousel.home-slider .owl-dots,
.owl-carousel.home-slider-loop-false .owl-dots {
  position: absolute;
  bottom: 100px;
  width: 100%;
}

.owl-carousel.home-slider .owl-dots .owl-dot,
.owl-carousel.home-slider-loop-false .owl-dots .owl-dot {
  width: 10px;
  height: 10px;
  margin: 5px;
  border-radius: 50%;
  border: 2px solid rgba(255, 255, 255, 0.5);
  outline: none !important;
  position: relative;
  -webkit-transition: 0.3s all ease;
  -o-transition: 0.3s all ease;
  transition: 0.3s all ease;
}

.owl-carousel.home-slider .owl-dots .owl-dot.active,
.owl-carousel.home-slider-loop-false .owl-dots .owl-dot.active {
  border: 2px solid white;
}

.owl-carousel.home-slider .owl-dots .owl-dot.active span,
.owl-carousel.home-slider-loop-false .owl-dots .owl-dot.active span {
  background: white;
}

.owl-carousel.major-caousel .owl-stage-outer {
  padding-top: 30px;
  padding-bottom: 30px;
}

.owl-carousel.major-caousel .owl-nav .owl-prev,
.owl-carousel.major-caousel .owl-nav .owl-next {
  -webkit-transition: 0.3s all ease;
  -o-transition: 0.3s all ease;
  transition: 0.3s all ease;
  color: #495057;
}

.owl-carousel.major-caousel .owl-nav .owl-prev:hover,
.owl-carousel.major-caousel .owl-nav .owl-prev:focus,
.owl-carousel.major-caousel .owl-nav .owl-next:hover,
.owl-carousel.major-caousel .owl-nav .owl-next:focus {
  color: #868e96;
  outline: none;
}

.owl-carousel.major-caousel .owl-nav .owl-prev.disabled,
.owl-carousel.major-caousel .owl-nav .owl-next.disabled {
  color: #dee2e6;
}

.owl-carousel.major-caousel .owl-nav .owl-prev {
  left: -60px !important;
}

.owl-carousel.major-caousel .owl-nav .owl-next {
  right: -60px !important;
}

.owl-carousel.major-caousel .owl-dots {
  bottom: -30px !important;
  position: relative;
}

.slider-item {
  display: flex;
  justify-content: center;
  filter: blur(1px);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  height: calc(100vh);
  min-height: 700px;
  position: relative;
}

.slider-logo {
  position: absolute;
  top: 20%;
  left: 30%;
  z-index: 2;
}
.slider-item:before {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.2);
  content: "";
}

.slider-item .slider-text {
  color: #fff;
  height: calc(100vh);
  min-height: 700px;
}

.slider-item .slider-text .child-name {
  font-size: 40px;
  color: #fff;
}

.slider-item .slider-text h1 {
  font-size: 5rem;
  color: #fff;
  line-height: 1.2;
  font-weight: 800 !important;
  text-transform: uppercase;
}

@media (max-width: 991px) {
  .slider-item .slider-text h1 {
    font-size: 40px;
  }
}

.slider-item .slider-text p {
  font-size: 20px;
  line-height: 1.5;
  font-weight: 300;
  color: white;
}

.slider-item .slider-text a {
  color: #fff;
  text-decoration: underline;
}

.slider-item .slider-text .btn {
  text-decoration: none;
}

.slider-wrap {
  position: relative;
}

/* .slider-wrap:after {
  content: '';
  width: 100%;
  height: 240px;
  background: #fff;
  z-index: 1;
  bottom: -210px;
  background-color: #f8f9fa;
  left: 0;
  position: absolute;
  -webkit-transform: skewY(-6deg);
  -ms-transform: skewY(-6deg);
  transform: skewY(-6deg);
  -webkit-transform-origin: top left;
  -ms-transform-origin: top left;
  transform-origin: top left;
} */

.slider-wrap.no-slanted:after {
  display: none;
}

.owl-custom-nav {
  float: right;
  position: relative;
  z-index: 10;
}

.owl-custom-nav .owl-custom-prev,
.owl-custom-nav .owl-custom-next {
  padding: 10px;
  font-size: 30px;
  background: #ccc;
  line-height: 0;
  width: 60px;
  text-align: center;
  display: inline-block;
}

.section {
  padding: 15em 0;
  position: relative;
  z-index: 2;
}

.section-subscribe {
  padding: 2em 0;
}

.quote-content {
  max-width: 350px;
  font-size: 16px;
  color: #000;
  font-style: italic;
  position: relative;
  display: table;
}

@media (max-width: 991px) {
  .quote-content {
    max-width: 100%;
  }
}

.quote-content .text,
.quote-content .quote {
  display: table-cell;
  vertical-align: top;
}

.quote-content .quote {
  font-size: 100px;
  line-height: 0.5;
  z-index: 0;
}

.quote-content .text {
  padding-left: 40px;
  line-height: 2;
}

.quote-content.float-right {
  position: relative;
  margin-left: 2em;
  margin-right: -10em;
}

@media (max-width: 991px) {
  .quote-content.float-right {
    margin-left: 0em;
    margin-right: 0em;
    float: none !important;
  }
}

.quote-content.float-left {
  position: relative;
  margin-right: 2em;
  margin-left: -10em;
}

@media (max-width: 991px) {
  .quote-content.float-left {
    margin-right: 0em;
    margin-left: 0em;
    float: none !important;
  }
}

.form-wrap {
  background: #fff;
  padding: 50px;
  -webkit-box-shadow: 0 20px 50px -10px rgba(0, 0, 0, 0.5);
  box-shadow: 0 20px 50px -10px rgba(0, 0, 0, 0.5);
}

.form-wrap h2 {
  font-size: 18px;
  text-transform: uppercase;
  color: #000;
  letter-spacing: 0.1em;
  margin-bottom: 40px;
}

.form-wrap.overlap {
  z-index: 99;
  position: relative;
  margin-top: -10em;
}

.form-wrap.primary {
  background: #ff7404;
}

.form-wrap.warning {
  background: #ffc107;
}

.form-wrap.primary h2,
.form-wrap.warning h2 {
  color: #fff;
}

.form-wrap.primary .form-control,
.form-wrap.warning .form-control {
  border: 2px solid transparent;
}

.form-wrap.primary input[type="submit"],
.form-wrap.warning input[type="submit"] {
  -webkit-box-shadow: 0 20px 50px -10px rgba(0, 0, 0, 0.5) !important;
  box-shadow: 0 20px 50px -10px rgba(0, 0, 0, 0.5) !important;
}

.select-wrap {
  position: relative;
}

.select-wrap .select-arrow-icon {
  font-size: 20px;
  color: #000;
  position: absolute;
  right: 20px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.select-wrap select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  height: 50px !important;
}

.contact-form-contact-info p {
  margin-bottom: 30px;
  font-size: 20px;
  line-height: 1.5;
  color: #000;
}

.contact-form-contact-info p .icon {
  top: -4px;
  position: relative;
  width: 0px;
  font-size: 30px;
  color: #ff7404;
}

.load-more {
  display: block;
  padding: 40px;
  text-align: center;
  background: #e9ecef;
  color: #000;
  font-size: 20px;
}

.load-more:hover {
  background: #ff7404;
  color: #fff;
}

.cover_1 {
  background-size: cover;
  background-position: center center;
  padding: 7em 0;
}

.cover_1 .sub-heading {
  color: rgba(255, 255, 255, 0.7);
  font-size: 22px;
}

.cover_1 .heading {
  font-size: 50px;
  color: white;
  font-weight: 300;
}

.heading {
  color: #000;
}

.heading.border-bottom {
  position: relative;
  padding-bottom: 30px;
}

.heading.border-bottom:before {
  bottom: 0;
  position: absolute;
  content: "";
  width: 50px;
  height: 2px;
  left: 50%;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
  background: #ff7404;
}

.text-black {
  color: #000 !important;
}

.stretch-section .video {
  display: block;
  position: relative;
  -webkit-box-shadow: 4px 4px 70px -20px rgba(0, 0, 0, 0.5);
  box-shadow: 4px 4px 70px -20px rgba(0, 0, 0, 0.5);
}

.media-feature {
  padding: 30px;
  -webkit-transition: 0.2s all ease-out;
  -o-transition: 0.2s all ease-out;
  transition: 0.2s all ease-out;
  background: #fff;
  z-index: 1;
  position: relative;
  border-bottom: 10px solid transparent;
  border-radius: 4px;
  font-size: 15px;
}

.media-feature .icon {
  font-size: 60px;
  color: #ff7404;
}

.media-feature h3 {
  font-size: 16px;
  text-transform: uppercase;
}

.media-feature:hover,
.media-feature:focus {
  -webkit-box-shadow: 0 2px 20px -3px rgba(0, 0, 0, 0.1);
  box-shadow: 0 2px 20px -3px rgba(0, 0, 0, 0.1);
  -webkit-transform: scale(1.05);
  -ms-transform: scale(1.05);
  transform: scale(1.05);
  z-index: 2;
  border-bottom: 10px solid #ff7404;
}

.media-custom {
  background: #fff;
  -webkit-transition: 0.3s all ease;
  -o-transition: 0.3s all ease;
  transition: 0.3s all ease;
  margin-bottom: 30px;
  position: relative;
  top: 0;
}

.media-custom .meta-post {
  color: #ced4da;
  font-size: 13px;
  text-transform: uppercase;
}

.media-custom > a {
  position: relative;
  overflow: hidden;
  display: block;
}

.media-custom .meta-chat {
  color: #ced4da;
}

.media-custom .meta-chat:hover {
  color: #868e96;
}

.media-custom img {
  -webkit-transition: 0.3s all ease;
  -o-transition: 0.3s all ease;
  transition: 0.3s all ease;
}

.media-custom:focus img,
.media-custom:hover img {
  -webkit-transform: scale(1.1);
  -ms-transform: scale(1.1);
  transform: scale(1.1);
}

.media-custom .media-body {
  padding: 30px;
}

.media-custom .media-body h3 {
  font-size: 20px;
}

.media-custom .media-body p:last-child {
  margin-bottom: 0;
}

.list-unstyled.check li {
  position: relative;
  padding-left: 30px;
  line-height: 1.3;
  margin-bottom: 10px;
}

.list-unstyled.check li:before {
  color: #5ac8d8;
  left: 0;
  font-family: "Ionicons";
  content: "\f122";
  position: absolute;
}

#modalAppointment .modal-content {
  border-radius: 0;
  border: none;
}

#modalAppointment .modal-body,
#modalAppointment .modal-footer {
  padding: 40px;
}

@media (max-width: 991px) {
  .img-md-fluid {
    max-width: 100%;
  }
}

.site-footer {
  position: relative;
  z-index: 5;
  padding: 5em 0;
  background-color: #f8f9fa;
}

/* .site-footer:before {
  content: '';
  width: 100%;
  height: 240px;
  z-index: -1;
  top: 0px;
  background-color: #fff;
  left: 0;
  position: absolute;
  -webkit-transform: skewY(-6deg);
  -ms-transform: skewY(-6deg);
  transform: skewY(-6deg);
  -webkit-transform-origin: top left;
  -ms-transform-origin: top left;
  transform-origin: top left;
} */

.site-footer p:last-child {
  margin-bottom: 0;
}

.site-footer h3 {
  font-size: 16px;
  margin-bottom: 20px;
  font-weight: bold;
}

.site-footer .footer-link li {
  line-height: 1.5;
  margin-bottom: 15px;
}

.site-footer hr {
  width: 100%;
}

.footer-social a {
  line-height: 0;
  border-radius: 50%;
  margin: 0 5px 5px 0;
  border: 1px solid rgba(255, 255, 255, 0.1);
  width: 30px;
  height: 30px;
  text-align: center;
  display: inline-block;
}

.footer-social a:hover {
  background: #fff;
  border-color: #fff;
  color: #000;
}

.link-thumbnail {
  display: block;
  position: relative;
  overflow: hidden;
  text-align: center;
}

@media (max-width: 991px) {
  .link-thumbnail {
    margin-bottom: 30px;
  }
}

.link-thumbnail img {
  position: relative;
  -webkit-transition: 0.3s all ease;
  -o-transition: 0.3s all ease;
  transition: 0.3s all ease;
}

.link-thumbnail .date {
  display: block;
  font-size: 12px;
  color: rgba(255, 255, 255, 0.4);
  text-transform: uppercase;
  margin-bottom: 10px;
}

.link-thumbnail .icon {
  position: absolute;
  -webkit-transition: 0.3s all ease;
  -o-transition: 0.3s all ease;
  transition: 0.3s all ease;
  z-index: 4;
  top: 50%;
  font-size: 30px;
  color: #fff;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  opacity: 0;
  visibility: hidden;
  margin-top: 30px;
}

.link-thumbnail h3 {
  opacity: 0;
  visibility: hidden;
  position: absolute;
  bottom: -20px;
  left: 0;
  color: #fff;
  font-size: 18px;
  -webkit-transition: 0.5s all ease;
  -o-transition: 0.5s all ease;
  transition: 0.5s all ease;
  z-index: 10;
  left: 50%;
  display: block;
  width: 77%;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
}

.link-thumbnail:before {
  opacity: 0;
  visibility: hidden;
  z-index: 2;
  -webkit-transition: 0.3s all ease;
  -o-transition: 0.3s all ease;
  transition: 0.3s all ease;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  content: "";
  background: rgba(0, 0, 0, 0.6);
  position: absolute;
}

.link-thumbnail:hover h3 {
  opacity: 1;
  visibility: visible;
  bottom: 30px;
}

.link-thumbnail:hover img {
  -webkit-transform: scale(1.1);
  -ms-transform: scale(1.1);
  transform: scale(1.1);
}

.link-thumbnail:hover .icon {
  opacity: 1;
  visibility: visible;
  margin-top: 0px;
}

.link-thumbnail:hover:before {
  opacity: 1;
  visibility: visible;
}

.border-t {
  border-top: 1px solid #f8f9fa;
}

.copyright {
  font-size: 14px;
}

.element-animate {
  opacity: 0;
  visibility: hidden;
}

#loader {
  position: fixed;
  width: 96px;
  height: 96px;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  background-color: rgba(255, 255, 255, 0.9);
  -webkit-box-shadow: 0px 24px 64px rgba(0, 0, 0, 0.24);
  box-shadow: 0px 24px 64px rgba(0, 0, 0, 0.24);
  border-radius: 16px;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: opacity 0.2s ease-out, visibility 0s linear 0.2s;
  -o-transition: opacity 0.2s ease-out, visibility 0s linear 0.2s;
  transition: opacity 0.2s ease-out, visibility 0s linear 0.2s;
  z-index: 1000;
}

#loader.fullscreen {
  padding: 0;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  -webkit-transform: none;
  -ms-transform: none;
  transform: none;
  background-color: #fff;
  border-radius: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
}

#loader.show {
  -webkit-transition: opacity 0.4s ease-out, visibility 0s linear 0s;
  -o-transition: opacity 0.4s ease-out, visibility 0s linear 0s;
  transition: opacity 0.4s ease-out, visibility 0s linear 0s;
  visibility: visible;
  opacity: 1;
}

#loader .circular {
  -webkit-animation: loader-rotate 2s linear infinite;
  animation: loader-rotate 2s linear infinite;
  position: absolute;
  left: calc(50% - 24px);
  top: calc(50% - 24px);
  display: block;
  -webkit-transform: rotate(0deg);
  -ms-transform: rotate(0deg);
  transform: rotate(0deg);
}

#loader .path {
  stroke-dasharray: 1, 200;
  stroke-dashoffset: 0;
  -webkit-animation: loader-dash 1.5s ease-in-out infinite;
  animation: loader-dash 1.5s ease-in-out infinite;
  stroke-linecap: round;
}

@-webkit-keyframes loader-rotate {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes loader-rotate {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@-webkit-keyframes loader-dash {
  0% {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -35px;
  }
  100% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -136px;
  }
}

@keyframes loader-dash {
  0% {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -35px;
  }
  100% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -136px;
  }
}

/* .bottom-slant-gray {
  position: relative;
} */

/* .bottom-slant-gray:after {
  content: '';
  width: 100%;
  height: 240px;
  background: #fff;
  z-index: -1;
  bottom: -200px;
  background-color: #f8f9fa;
  left: 0;
  position: absolute;
  -webkit-transform: skewY(-6deg);
  -ms-transform: skewY(-6deg);
  transform: skewY(-6deg);
  -webkit-transform-origin: top left;
  -ms-transform-origin: top left;
  transform-origin: top left;
} */

/* .top-slant-white {
  position: relative;
  margin-top: -200px;
} */

/* .top-slant-white:before {
  content: '';
  width: 100%;
  height: 240px;
  background: #fff;
  z-index: -1;
  top: 0px;
  background-color: #fff;
  left: 0;
  position: absolute;
  -webkit-transform: skewY(-6deg);
  -ms-transform: skewY(-6deg);
  transform: skewY(-6deg);
  -webkit-transform-origin: top left;
  -ms-transform-origin: top left;
  transform-origin: top left;
} */

/* .top-slant-white2 {
  position: relative;
} */

/* .top-slant-white2:before {
  content: '';
  width: 100%;
  height: 240px;
  background: #fff;
  z-index: -1;
  top: 0px;
  background-color: #fff;
  left: 0;
  position: absolute;
  -webkit-transform: skewY(-6deg);
  -ms-transform: skewY(-6deg);
  transform: skewY(-6deg);
  -webkit-transform-origin: top left;
  -ms-transform-origin: top left;
  transform-origin: top left;
} */

.relative-higher {
  position: relative;
  z-index: 5;
}

.service-block h3 {
  font-size: 1rem;
  text-transform: uppercase;
  letter-spacing: 0.2rem;
}

.about_img_1 {
  margin-bottom: 30px;
}

.wrap-icon {
  width: 80px;
  height: 80px;
  margin-bottom: 30px;
  border-radius: 50%;
  position: relative;
  display: block;
  background: #ff7404;
}

.wrap-icon > span {
  font-size: 2rem;
  color: #fff;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.comment-form-wrap {
  clear: both;
}

.comment-list {
  padding: 0;
  margin: 0;
}

.comment-list .children {
  padding: 50px 0 0 40px;
  margin: 0;
  float: left;
  width: 100%;
}

.comment-list li {
  padding: 0;
  margin: 0 0 30px 0;
  float: left;
  width: 100%;
  clear: both;
  list-style: none;
}

.comment-list li .vcard {
  width: 80px;
  float: left;
}

.comment-list li .vcard img {
  width: 50px;
  border-radius: 50%;
}

.comment-list li .comment-body {
  float: right;
  width: calc(100% - 80px);
}

.comment-list li .comment-body h3 {
  font-size: 20px;
}

.comment-list li .comment-body .meta {
  text-transform: uppercase;
  font-size: 13px;
  letter-spacing: 0.1em;
  color: #ccc;
}

.comment-list li .comment-body .reply {
  padding: 5px 10px;
  background: #e6e6e6;
  color: #000;
  text-transform: uppercase;
  font-size: 14px;
}

.comment-list li .comment-body .reply:hover {
  color: #000;
  background: #e3e3e3;
}

.search-form {
  background: #f7f7f7;
}

.search-form .form-group {
  position: relative;
}

.search-form .icon {
  position: absolute;
  top: 50%;
  right: 20px;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.post-meta {
  font-size: 13px;
  text-transform: uppercase;
  letter-spacing: 0.2em;
}

.post-meta a {
  color: #fff;
  border-bottom: 1px solid rgba(255, 255, 255, 0.5);
}

.sidebar-box {
  margin-bottom: 30px;
  padding: 25px;
  font-size: 15px;
  width: 100%;
  float: left;
  background: #fff;
}

.sidebar-box *:last-child {
  margin-bottom: 0;
}

.sidebar-box h3 {
  font-size: 18px;
  margin-bottom: 15px;
}

.categories li,
.sidelink li {
  position: relative;
  margin-bottom: 10px;
  padding-bottom: 10px;
  border-bottom: 1px dotted #dee2e6;
  list-style: none;
}

.categories li:last-child,
.sidelink li:last-child {
  margin-bottom: 0;
  border-bottom: none;
  padding-bottom: 0;
}

.categories li a,
.sidelink li a {
  display: block;
}

.categories li a span,
.sidelink li a span {
  position: absolute;
  right: 0;
  top: 0;
  color: #ccc;
}

.categories li.active a,
.sidelink li.active a {
  color: #000;
  font-style: italic;
}

.container {
  width: 85%;
  margin: 50px auto;
  text-align: justify;
}
.container .title {
  width: 60%;
  margin: 0px auto 30px auto;
}
.container .title h2 {
  font-family: "Lobster", cursive;
  font-size: 25px;
  margin-bottom: 120px;
}
.container .title p {
  color: #333;
  font-size: 14px;
}
.content .list {
  display: flex;
  justify-content: center;
  margin-bottom: 50px;
}
.content .list li {
  color: #000;
  margin: 0px 15px;
  transition: 0.3s ease-in-out;
  cursor: pointer;
  font-size: 17px;
  font-weight: 600;
  position: relative;
}
.content .list li::after {
  content: "";
  position: absolute;
  bottom: -3px;
  width: 0%;
  height: 2px;
  left: 50%;
  transform: translateX(-50%);
  background: #d4a373;
  transition: 0.4s ease-in-out;
}
.content .list li:hover::after {
  width: 100%;
}
.content .list li:first-child {
  color: #d4a373;
}
.box_flex {
  display: flex;
  -ms-flex: 4;
  flex: 4;
  flex-wrap: wrap;
}

.box {
  width: 32%;
  background: #fff;
  border-radius: 3px;
  padding: 10px;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  animation: movement 1s ease-in-out;
}
.box .box_img {
  width: 35%;
  text-align: center;
}
.box_img img {
  width: 130px;
  height: 130px;
}
